import "./Introduction.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import section1Img from "assets/images/introduction/introduction_section1.png";
import maitLogo from "assets/images/logos/MaiT_black.png";
import History from "components/introduction/History";

function Introduction() {
  const { t } = useTranslation();
  return (
    <div className="introductionWrap">
      <div className="introductionHeader">
        <div className="introductionHeader1">
          {t(`introduction.intro.text1`)}
        </div>
        <div className="introductionHeader2">
          {t(`introduction.intro.text2`)}
        </div>
      </div>
      <div className="introductionSection1">
        <div className="introductionText">
          <div className="introductionText1">
            {t(`introduction.section1.text1`)}
          </div>
          <div className="introductionText2">
            {t(`introduction.section1.text2`)}
          </div>
          <div className="introductionText3">
            {t(`introduction.section1.text3`)}
          </div>
        </div>
        <div className="introductionImage">
          <img src={section1Img} alt="introduction first img" />
        </div>
      </div>
      <div className="introductionSection2">
        <div className="introductionSectionTitle">
          {t(`introduction.section2.title`)}
        </div>
        <div className="introductionSection2Card">
          <div className="introductionSection2LogoWrap">
            <div className="introductionSection2Logo">
              <img src={maitLogo} alt="MaiT Logo" />
            </div>
          </div>
          <div className="introductionSection2Cards">
            <div className="introductionSection2CardsLine">
              <div className="introductionSection2CardItem">
                <div className="introductionSection2CardTitle">
                  {t(`introduction.section2.card1Title`)}
                </div>
                <div className="introductionSection2CardContents">
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card1Text1`)}
                    </div>
                  </div>
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card1Text2`)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="introductionSection2CardItem">
                <div className="introductionSection2CardTitle">
                  {t(`introduction.section2.card2Title`)}
                </div>
                <div className="introductionSection2CardContents">
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card2Text1`)}
                    </div>
                  </div>
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card2Text2`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="introductionSection2CardsLine">
              <div className="introductionSection2CardItem">
                <div className="introductionSection2CardTitle">
                  {t(`introduction.section2.card3Title`)}
                </div>
                <div className="introductionSection2CardContents">
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card3Text1`)}
                    </div>
                  </div>
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card3Text2`)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="introductionSection2CardItem">
                <div className="introductionSection2CardTitle">
                  {t(`introduction.section2.card4Title`)}
                </div>
                <div className="introductionSection2CardContents">
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card4Text1`)}
                    </div>
                  </div>
                  <div className="introductionSection2CardContentsLine">
                    <div className="introductionSection2CardContentsSpot"></div>
                    <div className="introductionSection2CardContentsText">
                      {t(`introduction.section2.card4Text2`)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="introductionSection2Table">
          <table>
            <tbody>
              <tr>
                <td>{t(`introduction.section2.tableTr1_1`)}</td>
                <td>{t(`introduction.section2.tableTr1_2`)}</td>
              </tr>
              <tr>
                <td>{t(`introduction.section2.tableTr2_1`)}</td>
                <td>{t(`introduction.section2.tableTr2_2`)}</td>
              </tr>
              <tr>
                <td>{t(`introduction.section2.tableTr3_1`)}</td>
                <td>{t(`introduction.section2.tableTr3_2`)}</td>
              </tr>
              <tr>
                <td>{t(`introduction.section2.tableTr4_1`)}</td>
                <td>
                  <p>
                    <span>{t(`introduction.section2.tableTr4_2_1_1`)}</span>
                    {t(`introduction.section2.tableTr4_2_1_2`)}
                  </p>
                  <p>
                    <span>{t(`introduction.section2.tableTr4_2_2_1`)}</span>
                    {t(`introduction.section2.tableTr4_2_2_2`)}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="introductionSection4">
        <div className="introductionSection4Title">
          {t(`introduction.section4.title`)}
        </div>
        <History />
      </div>
    </div>
  );
}
export default Introduction;
