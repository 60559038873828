import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cls } from "utils/cls";

const yearList = [2024, 2023, 2022];
export const History = () => {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState(2024);

  const renderText = (year) => {
    switch (year) {
      case 2022:
        return (
          <>
            {Array.from({ length: 11 }).map((_, index) => (
              <li key={index}>
                {t(`introduction.section4.2022.text${index + 1}`)}
              </li>
            ))}
          </>
        );
      case 2023:
        return (
          <>
            {Array.from({ length: 7 }).map((_, index) => (
              <li key={index}>
                {t(`introduction.section4.2023.text${index + 1}`)}
              </li>
            ))}
          </>
        );
      case 2024:
        return (
          <>
            {Array.from({ length: 7 }).map((_, index) => (
              <li key={index}>
                {t(`introduction.section4.2024.text${index + 1}`)}
              </li>
            ))}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <div className="text-start  transition-all duration-300">
      {yearList.map((year, index) => (
        <div key={year} className="flex transition-all">
          <div
            onClick={() => setSelectedYear(year)}
            className={cls(
              "w-16 md:w-20 flex-shrink-0 py-10 transition-all cursor-pointer",
              year === selectedYear
                ? "text-black font-bold pb-20"
                : "text-neutral-500",
              index === yearList.length - 1 ? "pb-56" : ""
            )}
          >
            <div className="flex items-center">
              <div className="hover:font-bold text-sm md:text-lg hover:text-black transition-all cursor-pointer">
                {year}
              </div>
            </div>
          </div>
          <div
            className="border-r border-neutral-400 relative cursor-pointer transition-all"
            onClick={() => setSelectedYear(year)}
          >
            <div
              className={cls(
                "absolute  flex items-center cursor-pointer top-6 md:top-8",
                year === selectedYear ? "-left-6" : "-left-5"
              )}
            >
              <div
                className={cls(
                  "flex items-center justify-center cursor-pointer rounded-full bg-opacity-20 duration-300",
                  year === selectedYear
                    ? "bg-pointColor w-12 h-12"
                    : "w-10 h-10 bg-neutral-700"
                )}
              >
                <div
                  className={cls(
                    " rounded-full transition-all cursor-pointer duration-500",
                    year === selectedYear
                      ? "w-6 h-6 bg-pointColor"
                      : "w-4 h-4 bg-neutral-700"
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mt-5 ml-10 md:ml-20 text-xs sm:text-md md:text-xl p-4">
            <ul
              className={cls(
                "space-y-4 transition-all duration-500",
                year === selectedYear
                  ? "max-h-[600px] opacity-100 "
                  : "max-h-0 opacity-0",
                index === yearList.length - 1 ? "mb-20" : ""
              )}
            >
              {renderText(year)}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default History;
